import "./scss/styles.scss";

const burger = document.querySelector(".burger");
const modal = document.querySelector(".modal");
const close = document.querySelector(".close");

burger.addEventListener("click", () => {
  modal.classList.remove("is-hidden");
  modal.style.transform = "translate(0, 0)";
});

close.addEventListener("click", () => {
  modal.style.transform = "translate(100%, 0)";
  modal.classList.add("is-hidden");
});
